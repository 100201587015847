import hasAllDocuments from './check-all-documents';

const requiredDocuments = [
  { name: 'Balance', code: 'balance', years: 3, template: null },
  { name: 'Estados Financieros', code: 'eerr', years: 3, template: null },
  { name: 'Autorización bureau de crédito empresa', code: 'company_credit_bureau', years: null, template: '/documents/company_credit_bureau.pdf' },
  { name: 'Autorización bureau de crédito accionista mayoritario', code: 'shareholder_credit_bureau', years: null, template: '/documents/shareholder_credit_bureau.pdf' },
  { name: 'Acta constitutiva', code: 'constitutive_act', years: null, template: null },
];

const chileRequiredDocuments = () => false;

const mexicoRequiredDocuments = (company) => hasAllDocuments(
  company.documents || [],
  requiredDocuments,
);

const countryMethod = {
  Mexico: mexicoRequiredDocuments,
  Chile: chileRequiredDocuments,
};

const hasAllRequiredDocuments = (company) => {
  if (!company) {
    return false;
  }
  const companyDocuments = company.documents || [];
  const countryMethodFunction = countryMethod[company.masterEntity.country.name]
   || chileRequiredDocuments;

  return countryMethodFunction(companyDocuments);
};

export {
  hasAllRequiredDocuments,
  requiredDocuments,
};

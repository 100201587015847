const passwordCheckers = [
  {
    label: 'Se requieren mínimo 8 caracteres',
    regexChecker: (input) => input.length >= 8,
  },
  {
    label: 'Se requiere al menos una letra',
    regexChecker: (input) => input.match('[a-zA-Z]'),
  },
  {
    label: 'Se requiere al menos un número',
    regexChecker: (input) => input.match('[0-9]'),
  },
];

const isPasswordValid = (password) => {
  if (passwordCheckers.filter(
    ({ regexChecker }) => !regexChecker(password),
  ).length) {
    return false;
  }
  return true;
};

export { passwordCheckers, isPasswordValid };

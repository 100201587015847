import React from 'react';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import Assignment from '@mui/icons-material/Assignment';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircle from '@mui/icons-material/CheckCircle';
import HourglassTop from '@mui/icons-material/HourglassTop';
import MarkEmailUnread from '@mui/icons-material/MarkEmailUnread';
import Paid from '@mui/icons-material/Paid';
import Plagiarism from '@mui/icons-material/Plagiarism';
import Warning from '@mui/icons-material/Warning';

export const statusToSpanish = (ready = false) => {
  const commonStatuses = {
    CREATED: 'Disponible',
    PROCESSING: 'Procesando cesión',
    REJECTED: 'Cesión rechazada',
    PENDINGRATIFICATION: 'Por ratificar',
    PENDINGDOCUMENTSREVIEW: 'Revisando documentos',
    REJECTEDRATIFICATION: 'Ratificación rechazada',
    PENDINGTRANSFER: 'En curse',
    REJECTEDTRANSFER: 'Depósito rechazado',
    TRANSFERED: 'Por cobrar',
    DEBT: 'En mora',
    EVALUATING: 'En evaluación',
    FINISHED: 'Recaudada',
    SURPLUSWITHDRAWN: 'Recaudada. Con Excedentes',
    LOANEDOTHER: 'Cedida',
    BADDEBT: 'Incobrable',
    EXPIRED: 'Expirada',
    RECEDED: 'Recedida',
  };

  const readyStatuses = {
    ...commonStatuses,
    PROCESSING: 'Cedida',
    PENDINGRATIFICATION: 'Ratificada',
    PENDINGDOCUMENTSREVIEW: 'Documentos revisados',
    PENDINGTRANSFER: 'Girado',
  };

  return ready ? readyStatuses : commonStatuses;
};

export const statusToIcon = {
  CREATED: <AssignmentTurnedIn />,
  PROCESSING: <Assignment />,
  REJECTED: <Warning />,
  PENDINGRATIFICATION: <MarkEmailUnread />,
  PENDINGDOCUMENTSREVIEW: <Plagiarism />,
  REJECTEDRATIFICATION: <Warning />,
  PENDINGTRANSFER: <AccountBalanceWallet />,
  REJECTEDTRANSFER: <Warning />,
  TRANSFERED: <Paid />,
  DEBT: <Warning />,
  EVALUATING: <HourglassTop />,
  FINISHED: <CheckCircle />,
  SURPLUSWITHDRAWN: <CheckCircle />,
  LOANEDOTHER: <Warning />,
  BADDEBT: <Warning />,
  EXPIRED: <Warning />,
  RECEDED: <Warning />,
};

export const statusToActiveStep = {
  PROCESSING: 0,
  REJECTED: 0,
  PENDINGRATIFICATION: 1,
  PENDINGDOCUMENTSREVIEW: 1,
  REJECTEDTRANSFER: 1,
  REJECTEDRATIFICATION: 1,
  PENDINGTRANSFER: 2,
  TRANSFERED: 3,
  DEBT: 3,
  FINISHED: 3,
  EXPIRED: 3,
};

export const statusToColor = {
  PROCESSING: 'gold',
  REJECTED: 'red',
  PENDINGRATIFICATION: 'gold',
  PENDINGDOCUMENTSREVIEW: 'gold',
  REJECTEDRATIFICATION: 'red',
  PENDINGTRANSFER: 'gold',
  REJECTEDTRANSFER: 'red',
  TRANSFERED: 'green',
  DEBT: 'red',
  FINISHED: 'green',
  EXPIRED: 'gold',
};

export const statusToStepLabels = (status) => {
  let labels = [statusToSpanish()[status]];
  if (statusToActiveStep[status] === 0) {
    labels = labels.concat(['Ratificación', 'Depósito', 'Recaudación']);
  }
  if (statusToActiveStep[status] === 1) {
    labels.unshift(['Cedida']);
    labels = labels.concat(['Depósito', 'Recaudación']);
  }
  if (statusToActiveStep[status] === 2) {
    labels.unshift('Cedida', 'Ratificada');
    labels.push('Recaudación');
  }
  if (statusToActiveStep[status] === 3) {
    labels.unshift('Cedida', 'Ratificada', 'Depositada');
  }
  return labels;
};

/* eslint-disable import/prefer-default-export */
export const userHasActivePlanSubscription = (user, planId) => {
  const userPlanSubscriptions = user.userPlanSubscriptions.edges.map(
    (edge) => edge.node,
  );
  return userPlanSubscriptions.some(
    (usp) => usp.subscription.plan.id === planId,
  );
};

export const hasCollectionAvailable = (user) => user && userHasActivePlanSubscription(user, '1') && user?.selectedCompany?.hasCollectionAcceptedTerms;
